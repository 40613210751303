import { ReactComponentElement } from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

import styles from './stars.module.css';

interface Props {
    ileGwiazdek: number;
}

const Stars = ({ ileGwiazdek }: Props) => {
    const formatStars = (ile: number) => {
        const array = [
            <AiOutlineStar key={0} />,
            <AiOutlineStar key={1} />,
            <AiOutlineStar key={2} />,
            <AiOutlineStar key={3} />,
            <AiOutlineStar key={4} />
        ];

        for (let i = 0; i < ile; i++) {
            array[i] = <AiFillStar key={i} />
        }

        return array;
    }


    return (
        <span className={styles.stars}>
            {
                formatStars(ileGwiazdek).map(item => item)
            }
        </span>
    )
}

export default Stars
