import { BrowserRouter, Routes, Route } from "react-router-dom";
import DefaultLayout from './layouts/DefaultLayout';
import { Home, Oferta, Wyszukiwarka, Kontakt, NotFound, About, Kredyty, PolitykaPrywatnosci } from './pages';
import ScrollToTop from "./components/scrollToTop/ScrollToTop";


function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Home />} />
          <Route path="/o-nas" element={<About />} />
          <Route path="/oferta/:numer" element={<Oferta />} />
          <Route path="/wyszukiwarka" element={<Wyszukiwarka />} />
          <Route path="/kredyty" element={<Kredyty />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/polityka-prywatnosci" element={<PolitykaPrywatnosci />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
