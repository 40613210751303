import { useState, useEffect } from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { PiInstagramLogoFill } from 'react-icons/pi';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';
import axiosClient from '../../AxiosClient';
import axios from 'axios';

import styles from './header.module.css';

interface Response {
    id: number;
    location: string;
    key: string;
    value: string;
    created_at: string;
    updated_at: string;
};

const Header = () => {
    const [isNavActive, setIsNavActive] = useState<boolean>(false);
    const [socialMedia, setSocialMedia] = useState<Response[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/content/header',
            cancelToken: source.token
        })
            .then(res => {
                setError(null);
                setSocialMedia(res.data);
            })
            .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));

        return () => {
            source.cancel();
        }

    }, []);

    const toggleNav = (): void => {
        setIsNavActive(prev => {
            if (prev) {
                document.body.style.overflow = 'auto';
            }
            else if (!prev) {
                document.body.style.overflow = 'hidden';
            }

            return !prev;
        });
    }

    const changeLocation = (): void => {
        setIsNavActive(false);
        document.body.style.overflow = 'auto';
    }

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <header className={styles.header}>
            <div className={styles.header__top}>
                <a aria-label='Facebook' className={styles.header__top__link} href={socialMedia?.filter((item) => {
                    return item.key === 'facebook';
                })[0].value} target='_blank'>
                    <FaFacebookF className={styles.header__icon} />
                </a>
                <a aria-label='Twitter' className={styles.header__top__link} href={socialMedia?.filter((item) => {
                    return item.key === 'twitter';
                })[0].value} target='_blank'>
                    <FaTwitter className={styles.header__icon} />
                </a>
                <a aria-label='Instagram' className={styles.header__top__link} href={socialMedia?.filter((item) => {
                    return item.key === 'instagram';
                })[0].value} target='_blank'>
                    <PiInstagramLogoFill className={styles.header__icon} />
                </a>
                <a aria-label='Linkedin' className={styles.header__top__link} href={socialMedia?.filter((item) => {
                    return item.key === 'linkedin';
                })[0].value} target='_blank'>
                    <FaLinkedinIn className={styles.header__icon} />
                </a>
                <a aria-label='YouTube' className={styles.header__top__link} href={socialMedia?.filter((item) => {
                    return item.key === 'youtube';
                })[0].value} target='_blank'>
                    <FaYoutube className={styles.header__icon} />
                </a>
            </div>
            <div className={styles.header__bottom_mobile}>
                <div className={styles.header__bottom_mobile__top}>
                    <Link to='/'>
                        <img src={`${process.env.REACT_APP_BACKEND_URL}/storage/assets/logo.svg`} alt="logo firmy" className={styles.header__logo} />
                    </Link>
                    {isNavActive ?
                        <IoMdClose onClick={toggleNav} className={styles.header__toggle} /> :
                        <GiHamburgerMenu onClick={toggleNav} className={styles.header__toggle} />}
                </div>
                <nav className={`${styles.header__nav_mobile} ${isNavActive && styles.header__nav_mobile_active}`}>
                    <Link onClick={changeLocation} tabIndex={isNavActive ? 0 : -1} to='/' className={styles.header__navLink}>Home</Link>
                    <Link onClick={changeLocation} tabIndex={isNavActive ? 0 : -1} to='/o-nas' className={styles.header__navLink}>O nas</Link>
                    <Link onClick={changeLocation} tabIndex={isNavActive ? 0 : -1} to='/wyszukiwarka' className={styles.header__navLink}>Oferty nieruchomości</Link>
                    <Link onClick={changeLocation} tabIndex={isNavActive ? 0 : -1} to='/kredyty' className={styles.header__navLink}>Kredyty</Link>
                    <Link onClick={changeLocation} tabIndex={isNavActive ? 0 : -1} to='/kontakt' className={styles.header__navLink}>Kontakt</Link>
                    <a onClick={changeLocation} tabIndex={isNavActive ? 0 : -1} href="https://blog.admhome.com.pl/" className={styles.header__navLink}>Blog</a>
                </nav>
            </div>
            <div className={styles.header__bottom}>
                <Link to='/'>
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/storage/assets/logo.svg`} alt="logo firmy" className={styles.header__logo} />
                </Link>
                <nav className={styles.header__nav}>
                    <Link to='/' className={styles.header__navLink}>Home</Link>
                    <Link to='/o-nas' className={styles.header__navLink}>O nas</Link>
                    <Link to='/wyszukiwarka' className={styles.header__navLink}>Oferty nieruchomości</Link>
                    <Link to='/kredyty' className={styles.header__navLink}>Kredyty</Link>
                    <Link to='/kontakt' className={styles.header__navLink}>Kontakt</Link>
                    <a href="https://blog.admhome.com.pl/" className={styles.header__navLink}>Blog</a>
                </nav>
            </div>
        </header>
    )
}

export default Header
