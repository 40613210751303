import { Helmet } from 'react-helmet';

import styles from './kredyty.module.css';

const Kredyty = () => {
    return (
        <main className={styles.main}>
            <Helmet>
                <title>Kredyty</title>
                <meta name='description' content='Współpracujemy z wieloma bankami udzielającymi kredytów na sfinansowanie nieruchomości. Głównym partnerem obsługi kredytowej, zapewniającym naszym klientom pełną gamę produktów finansowych, takich jak:kredyty hipoteczne, refinansowe, konsolidacyjne, inwestycyjne, produkty inwestycyjno - oszczędnościowe, produkty ubezpieczeniowe' />
            </Helmet>
            <h1 className={styles.heading}>Kredyty</h1>
            <p className={styles.text}>Współpracujemy z wieloma bankami udzielającymi kredytów na sfinansowanie nieruchomości.</p>
            <p className={styles.text}>Głównym partnerem obsługi kredytowej, zapewniającym naszym klientom pełną gamę produktów finansowych, takich jak:</p>
            <ul className={styles.text_list}>
                <li className={styles.text__listItem}>kredyty hipoteczne</li>
                <li className={styles.text__listItem}>refinansowe</li>
                <li className={styles.text__listItem}>konsolidacyjne</li>
                <li className={styles.text__listItem}>inwestycyjne</li>
                <li className={styles.text__listItem}>produkty inwestycyjno - oszczędnościowe</li>
                <li className={styles.text__listItem}>produkty ubezpieczeniowe</li>
            </ul>
            <p className={styles.text}>jest największa niezależna firma doradztwa finansowego w Polsce.
                Firma działa na rynku od 2000 roku i posiada blisko 50 oddziałów w 40 miastach.</p>
            <b className={styles.text_bold}>ŚWIADCZONE PRZEZ NAS USŁUGI W ZAKRESIE DORADZTWA KREDYTOWEGO
                SĄ CAŁKOWICIE BEZPŁATNE DLA NASZYCH KLIENTÓW!</b>
            <p className={styles.text}>Atutami naszej współpracy z bankami i doradcami finansowymi, są niezależność oraz bezstronność.
                Doradcy finansowi negocjują indywidualne warunki kredytu dla Klienta.
                Firma zapewnia indywidualną opiekę jednego doradcy od pierwszego kontaktu do wypłaty środków przez bank.
                Spotkania z doradcą odbywają się w dogodnym dla Klienta miejscu i terminie.
                Dostęp do kredytu jest często szybszy dzięki uproszczonym procedurom.</p>
            <b className={styles.text_bold}>Zapraszamy do współpracy!!!</b>
        </main>
    )
}

export default Kredyty
