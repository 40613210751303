import { Helmet } from 'react-helmet';

import styles from './about.module.css';

const About = () => {
    return (
        <main className={styles.main}>
            <Helmet>
                <title>O firmie</title>
                <meta name='description' content='Zajmujemy się obrotem nieruchomościami od 2004 roku. Posiadamy licencję zawodową nr 14012 na świadczenie usług w zakresie pośrednictwa w obrocie nieruchomościami nadaną przez Ministra Infrastruktury jak również Polisę Ubezpieczeniową Odpowiedzialności Cywilnej. Jesteśmy członkiem Bielsko-Częstochowsko-Katowickiego Stowarzyszenia Pośredników w Obrocie Nieruchomościami (w skrócie BCK-SPON). Uczestniczymy systematycznie w seminariach i szkoleniach organizowanych przez profesjonalistów, stale podnosząc swoje kwalifikacje.' />
            </Helmet>
            <h1 className={styles.heading}>O firmie</h1>
            <p className={styles.section__heading}>Witamy!</p>
            <section className={styles.section}>
                <h2 className={styles.section__heading}>Na początek krótko 'o nas':</h2>
                <p className={styles.section__text}>Zajmujemy się obrotem nieruchomościami od 2004 roku. Posiadamy licencję zawodową nr 14012 na świadczenie usług w zakresie pośrednictwa w obrocie nieruchomościami nadaną przez Ministra Infrastruktury jak również Polisę Ubezpieczeniową Odpowiedzialności Cywilnej. Jesteśmy członkiem Bielsko-Częstochowsko-Katowickiego Stowarzyszenia Pośredników w Obrocie Nieruchomościami (w skrócie BCK-SPON).
                    Uczestniczymy systematycznie w seminariach i szkoleniach organizowanych przez profesjonalistów, stale podnosząc swoje kwalifikacje.</p>
            </section>
            <section className={styles.section}>
                <h2 className={styles.section__heading}>George Moore powiedział:</h2>
                <q className={styles.section__quote}>Człowiek wędruje po świecie w poszukiwaniu tego, czego mu trzeba i wraca do domu, by tutaj to znaleźć.</q>
                <p className={styles.section__text}>
                    Nie pomożemy Państwu znaleźć tego czegoś…, ale z pewnością pomożemy Państwu w znalezieniu takiego domu,
                    aby w nim mogli Państwo odnaleźć TO COŚ !
                    Dlatego też, zapraszamy do skorzystania z naszych usług. Kontaktując się z naszym biurem, nasi pracownicy będą do Państwa dyspozycji zarówno w siedzibie biura, które mieści się w śródmieściu Katowic w sąsiedztwie Placu Miarki, w budynku przy ul. J. Ligonia 8, jak i w terenie.
                    Podane godziny pracy biura (w tygodniu od 10.00 do 16.00) są jedynie orientacyjne, poza nimi istnieje możliwość uzgodnienia indywidualnego terminu spotkania, dogodnego dla Państwa, również w weekendy.

                    Decydując się na korzystanie z usług naszego biura, otrzymacie Państwo gwarancję bezpieczeństwa podczas całego procesu transakcji. Działając w oparciu o obowiązujące przepisy prawne i zasady etyki zawodowej, jednocześnie wykorzystując wieloletnie doświadczenie na rynku, staramy się obsługiwać naszych klientów z dbałością o najdrobniejsze szczegóły. U nas klient jest najważniejszy ! Państwa zadowolenie z naszej obsługi stanowi priorytet naszych działań ! Pomożemy Państwu sprawnie przebrnąć przez proces transakcji, nierzadko trudny i skomplikowany. Działamy w sposób, który pozwala na załatwienie wszelkich formalności, starając się przy tym minimalnie angażować Państwa i wyręczać przy każdej sposobności.
                </p>
                <ul className={styles.section__list}>
                    <li className={styles.section__listItem}>W ramach współpracy z naszym biurem, gwarantujemy naszym klientom szeroką reklamę ofert nieruchomości na blisko kilkunastu portalach internetowych, ponieważ współpracujemy z największymi firmami ogłoszeniowymi na rynku. Dodatkowo nasza firma posiada własne strony www (w tym na portalu: gratka.pl oferty.net i oto.dom) jak również dysponuje własnymi bannerami reklamowymi będącymi do Państwa dyspozycji.</li>
                    <li className={styles.section__listItem}>W oparciu o wieloletnie doświadczenie w branży, sprawnie doradzimy Państwu w jaki sposób skutecznie sprzedać lub wynająć nieruchomość.</li>
                    <li className={styles.section__listItem}>W naszym biurze uzyskacie Państwo porady dotyczące wyceny rynkowej nieruchomości, w razie potrzeby skontaktujemy Państwa z licencjonowanymi specjalistami, zajmującymi się profesjonalną wyceną.</li>
                    <li className={styles.section__listItem}>Uzyskacie również Państwo bezpłatną pomoc z naszej strony w załatwieniu formalności kredytowych przy zakupie nieruchomości, ponieważ współpracujemy z wieloma bankami oferującymi kredyty hipoteczne, i.in. formy finansowania, dostępne na rynku.</li>
                    <li className={styles.section__listItem}>Pomożemy Państwu w regulacji stanu prawnego nieruchomości. Otrzymaliście Państwo nieruchomość w spadku i nie wiecie w jaki sposób uporządkować sprawy prawne? Jesteśmy od tego aby Państwu pomóc. W tym zakresie współpracujemy z Kancelariami Prawnymi i Notarialnymi.</li>
                    <li className={styles.section__listItem}>Doradzimy Państwu również w sferze inwestycyjnej. - Jak dobrze kupić nieruchomość, aby nie stracić?
                        To częste pytanie, które słyszymy od naszych klientów. Państwu również postaramy się na nie odpowiedzieć.</li>
                    <li className={styles.section__listItem}>Współpracujemy też z deweloperami, jeśli więc za naszym pośrednictwem kupią Państwo grunt, możemy skontaktować Państwa ze sprawdzonymi-rzetelnymi firmami deweloperskimi jak również polecić firmę, która zaprojektuje dom na miarę Państwa potrzeb i zaaranżuje wystrój wnętrz.</li>
                    <li className={styles.section__listItem}>Naszym klientom pomagamy również podczas przeprowadzki czy opróżnienia mieszkania, współpracujemy z rzetelną firmą transportową, która załatwi to za Państwa.</li>
                </ul>
            </section>
            <footer className={styles.footer}>
                <p className={styles.section__heading}>Zapraszamy!</p>
                <p className={styles.section__heading}>Zespół biura ADM NIERUCHOMOŚCI</p>
            </footer>
        </main>
    )
}

export default About
