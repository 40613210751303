import { useState, useEffect } from 'react';
import axiosClient from '../../AxiosClient';
import axios from 'axios';

import styles from './politykaPrywatnosci.module.css';

const PolitykaPrywatnosci = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [text, setText] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/content/polityka-prywatnosci',
            cancelToken: source.token
        })
            .then(res => {
                setText(res.data[0].value);
                setError(null);
            })
            .catch(err => setError('Coś poszło nie tak spróbuj ponownie później...'))
            .finally(() => setIsLoading(false));

        return () => {
            source.cancel();
        }

    }, []);

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <>
            {isLoading ? <img src='/loading.gif' className='loading' /> :
                <pre className={styles.text}>
                    {text}
                </pre>
            }
        </>
    )
}

export default PolitykaPrywatnosci
