import { useState, useEffect, FormEvent } from 'react';
import axiosClient from '../../AxiosClient';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import styles from './kontakt.module.css';

interface Response {
    id: number;
    location: string;
    key: string;
    value: string;
    created_at: string;
    updated_at: string;
}

const Kontakt = () => {
    const [validationError, setValidationError] = useState<string | null>(null);
    const [notification, setNotification] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [contact, setContact] = useState<Response[] | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/content/contact',
            cancelToken: source.token
        })
            .then(res => {
                setContact(res.data);
                setError(null);
                setIsLoading(false);
            })
            .catch(err => {
                setError('Coś poszło nie tak, spróbuj ponownie później...');
                setIsLoading(false);
            })

        return () => {
            source.cancel();
        }

    }, []);

    const sendMessage = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const name = form.querySelector('#imie-i-nazwisko') as HTMLInputElement;
        const email = form.querySelector('#email') as HTMLInputElement;
        const nr_tel = form.querySelector('#nr_tel') as HTMLInputElement;
        const wiadomosc = form.querySelector('#wiadomosc') as HTMLTextAreaElement;

        if (!name?.value || !email?.value || !nr_tel?.value || !wiadomosc?.value) {
            setValidationError('Wszystkie pola są wymagane');
            return;
        }

        if (!/^[+0-9][-0-9 ()]*[0-9]$/.test(nr_tel.value)) {
            setValidationError('Podaj poprawny numer telefonu');
            return;
        }

        if (wiadomosc.value.length > 1000) {
            setValidationError('Wiadomość może mieć maksymalnie 1000 znaków');
            return;
        }

        axiosClient({
            method: 'post',
            url: '/contact',
            data: {
                imieNazwisko: name.value,
                email: email.value,
                nr_tel: nr_tel.value,
                wiadomosc: wiadomosc.value
            }
        })
            .then(res => {
                console.log(res.data);
                setValidationError(null);
                form.reset();
                setNotification(true);
                setTimeout(() => setNotification(false), 4000);
            })
            .catch(err => setValidationError(err?.response?.data.message));
    }

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <>
            {
                isLoading ? <img className='loading' src="/loading.gif" alt="ładowanie" /> :
                    <main className={styles.main}>
                        <Helmet>
                            <title>Kontakt</title>
                            <meta name='description' content='Formularz kontaktowy' />
                        </Helmet>
                        <p role='alert' aria-live={notification ? 'assertive' : 'off'} className={`${styles.notification} ${notification && styles.notification_active}`}>
                            Pomyślnie wysłano wiadomość
                        </p>
                        <h1 className={styles.heading}>Kontakt</h1>
                        <section className={styles.info}>
                            <article className={styles.info__child}>
                                <h3 className={styles.info__heading}>Siedziba biura:</h3>
                                <p className={styles.info__text}>{contact?.filter((item) => {
                                    return item.key === 'siedzibaAdres';
                                })[0].value}</p>
                                <p className={styles.info__text}>{contact?.filter((item) => {
                                    return item.key === 'siedzibaMiasto';
                                })[0].value}</p>
                            </article>
                            <article className={styles.info__child}>
                                <h3 className={styles.info__heading}>Godziny pracy biura:</h3>
                                <p className={styles.info__text}>{contact?.filter((item) => {
                                    return item.key === 'godzinyPracy';
                                })[0].value}</p>
                                <p className={styles.info__text}>{contact?.filter((item) => {
                                    return item.key === 'godzinyPracyText';
                                })[0].value}</p>
                            </article>
                            <article className={styles.info__child}>
                                <p className={styles.info__text}>Tel/fax: <a className={styles.info__link} href={`tel:${contact?.filter((item) => {
                                    return item.key === 'telFax';
                                })[0].value}`}>{contact?.filter((item) => {
                                    return item.key === 'telFax';
                                })[0].value}</a></p>
                                <p className={styles.info__text}>Mob.: <a className={styles.info__link} href={`tel:${contact?.filter((item) => {
                                    return item.key === 'mobile';
                                })[0].value}`}>{contact?.filter((item) => {
                                    return item.key === 'mobile';
                                })[0].value}</a> </p>
                                <p className={styles.info__text}>E-mail: <a className={styles.info__link} href={`mailto:${contact?.filter((item) => {
                                    return item.key === 'email';
                                })[0].value}`}>{contact?.filter((item) => {
                                    return item.key === 'email';
                                })[0].value}</a></p>
                            </article>
                        </section>
                        <form onSubmit={sendMessage} className={styles.form}>
                            <div className={styles.form__top}>
                                <div className={styles.form__container}>
                                    <label htmlFor="imie-i-nazwisko" className={styles.form__label}>Imię i nazwisko</label>
                                    <input className={styles.form__input} type="text" id='imie-i-nazwisko' placeholder='Jan Kowalski' />
                                </div>
                                <div className={styles.form__container}>
                                    <label htmlFor="email" className={styles.form__label}>Email</label>
                                    <input className={styles.form__input} type="email" id='email' placeholder='user@example.com' />
                                </div>
                                <div className={styles.form__container}>
                                    <label htmlFor="nr_tel" className={styles.form__label}>Telefon komórkowy</label>
                                    <input className={styles.form__input} type="text" id='nr_tel' placeholder='123 456 789' />
                                </div>
                            </div>
                            <label className={styles.form__label} htmlFor="wiadomosc">Wiadomość</label>
                            <textarea className={styles.form__textarea} placeholder='Wiadomość' id="wiadomosc" rows={7}></textarea>
                            {validationError && <p role='alert' aria-live='assertive' className={styles.form__error}>{validationError}</p>}
                            <button className={styles.form__button}>Wyślij wiadomość</button>
                        </form>
                    </main>
            }
        </>
    )
}

export default Kontakt
