import { useState, useEffect } from 'react';
import { Hero, HomeSearch, OfferGroup, Invitation } from '../../sections';
import OfferTile from '../../components/offerTile/OfferTile';
import { Helmet } from 'react-helmet';
import axiosClient from '../../AxiosClient';
import axios from 'axios';

interface Response {
    id: number;
    location: string;
    key: string;
    value: string;
    created_at: string;
    updated_at: string;
}

interface Offer {
    id: number;
    nazwa: string;
    miasto: string;
    dzielnica: string | null;
    numer_oferty: string;
    typ_nieruchomosci: string;
    rodzaj: string;
    liczba_pokoi: number | null;
    pietro: number | null;
    powierzchnia: number;
    cena_za_metr_kwadratowy: number;
    cena: number;
    rodzaj_budynku: string | null;
    standard: number | null;
    stan_lokalu: string | null;
    okna: string | null;
    balkon: number | null;
    opis: string;
    agent_id: number;
    client_id: null;
    aktywna: 1;
    created_at: string;
    updated_at: string;
}

const Home = () => {
    const [fetchedData, setFetchedData] = useState<Response[] | null>(null);
    const [featuredOffers, setFeaturedOffers] = useState<Offer[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/content/homepage',
            cancelToken: source.token
        })
            .then(res => {
                setFetchedData(res.data);
                axiosClient({
                    method: 'get',
                    url: '/featured-offers',
                    cancelToken: source.token
                })
                    .then(res => {
                        setFeaturedOffers(res.data);
                        setError(null);
                        setIsLoading(false);
                    })
                    .catch(err => {
                        setError('Coś poszło nie tak, spróbuj ponownie później...');
                    });
            })
            .catch(err => {
                setIsLoading(false);
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            });

        return () => {
            source.cancel();
        }
    }, []);

    if (error) {
        return <p role='alert' aria-live='assertive' className='error'>{error}</p>
    }

    return (
        <>
            <Helmet>
                <title>{fetchedData?.filter((item) => {
                    return item.key === 'title';
                })[0].value}</title>
                <meta name='description' content={fetchedData?.filter((item) => {
                    return item.key === 'description';
                })[0].value} />
            </Helmet>
            {
                isLoading ? <img className='loading' src="/loading.gif" alt="ładowanie" /> :
                    <main>
                        <Hero
                            subHeading={fetchedData?.filter((item) => {
                                return item.key === 'heroSubHeading';
                            })[0].value}
                            heading={fetchedData?.filter((item) => {
                                return item.key === 'heroHeading';
                            })[0].value}
                            text={fetchedData?.filter((item) => {
                                return item.key === 'heroText';
                            })[0].value}
                            linkText={fetchedData?.filter((item) => {
                                return item.key === 'heroLinkText';
                            })[0].value}
                            linkDestination={fetchedData?.filter((item) => {
                                return item.key === 'heroLinkDestination';
                            })[0].value}
                            image={fetchedData?.filter((item) => {
                                return item.key === 'heroImg';
                            })[0].value}
                        />
                        <HomeSearch
                            heading={fetchedData?.filter((item) => {
                                return item.key === 'homeSearchHeading';
                            })[0].value}
                            items={fetchedData?.filter((item) => {
                                return item.key === 'homeSearchItems';
                            })[0].value}
                        />
                        <OfferGroup heading='Wybrane ogłoszenia'>
                            {
                                featuredOffers?.map(offer => {
                                    return (
                                        <OfferTile
                                            key={offer.id}
                                            id={offer.numer_oferty}
                                            city={offer.miasto}
                                            type={`${offer.typ_nieruchomosci} na ${offer.rodzaj}`}
                                            surface={offer.powierzchnia}
                                            rooms={offer?.liczba_pokoi}
                                            floor={offer?.pietro}
                                            pricePerSquareMeter={offer.cena_za_metr_kwadratowy}
                                            price={offer.cena}
                                        />
                                    )
                                })
                            }
                        </OfferGroup>
                        <Invitation
                            heading={fetchedData?.filter((item) => {
                                return item.key === 'homeInvitationHeading';
                            })[0].value}
                            text={fetchedData?.filter((item) => {
                                return item.key === 'homeInvitationText';
                            })[0].value}
                            img={fetchedData?.filter((item) => {
                                return item.key === 'homeInvitationImg';
                            })[0].value}
                        />
                    </main>
            }
        </>
    )
}

export default Home
