import { useState, useEffect } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import formatNumber from '../../utilities/formatNumber';
import axiosClient from '../../AxiosClient';
import axios from 'axios';

import styles from './offerTile.module.css';

interface Props {
    id: string;
    city: string;
    type: string;
    surface: number;
    rooms: number | null;
    floor: number | null;
    pricePerSquareMeter: number;
    price: number;
}

const OfferTile = (props: Props) => {
    const [thumbnail, setThumbnail] = useState<string>('/image-placeholder.png');

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: `thumbnail/${props.id}`,
            cancelToken: source.token
        })
            .then(res => setThumbnail(`${process.env.REACT_APP_BACKEND_URL}/storage/offers/${res.data}`))
            .catch(err => console.error(err));

        return () => {
            source.cancel();
        }

    }, []);

    return (
        <article className={styles.offer}>
            <Link className={styles.offer__imgLink} to={`/oferta/${props.id}`}>
                <img style={{ objectFit: thumbnail === '/image-placeholder.png' ? 'contain' : 'cover' }} src={thumbnail} alt="miniatura oferty" className={styles.offer__img} />
            </Link>
            <div className={styles.offer__right}>
                <p className={styles.offer__city}>{props.city}</p>
                <p className={styles.offer__type}>{props.type}</p>
                <div className={`${styles.offer__metadata} ${(!props.rooms && !props.floor) && styles.offer__metadata_column}`}>
                    <p className={styles.offer__metadata__text}>Powierzchnia: {props.surface}m&sup2;</p>
                    {
                        props.rooms &&
                        <p className={styles.offer__metadata__text}>Pokoje: {props.rooms}</p>
                    }
                    {
                        props.floor !== null &&
                        <p className={styles.offer__metadata__text}>Piętro: {props.floor === 0 ? 'parter' : props.floor}</p>
                    }
                    <p className={styles.offer__metadata__text}>Cena za m&sup2;: {formatNumber(props.pricePerSquareMeter)} PLN</p>
                </div>
                <div className={styles.offer__priceContainer}>
                    <p className={styles.offer__price}><Link className={styles.offer__price__link} to={`/oferta/${props.id}`}>Cena: {formatNumber(props.price)} PLN</Link></p>
                    <Link aria-label='Przejdź do oferty' to={`/oferta/${props.id}`} className={styles.offer__link}>
                        <BsArrowRight className={styles.offer__link__icon} />
                    </Link>
                </div>
            </div>
        </article>
    )
}

export default OfferTile
