import { useState, FormEvent, useRef } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import styles from './homeSearch.module.css';
import axiosClient from '../../AxiosClient';

interface Miasto {
    miasto: string;
}

interface Dzielnica {
    dzielnica: string;
}

interface Props {
    heading?: string;
    items?: string;
}

const HomeSearch = ({ heading, items }: Props) => {
    const navigate = useNavigate();
    const [isFormActive, setIsFormActive] = useState<boolean>(false);
    const cityInputRef = useRef<HTMLInputElement>(null);
    const dzielnicaInputRef = useRef<HTMLInputElement>(null);
    const [cityAutocompleteArr, setCityAutocompleteArr] = useState<Miasto[] | []>([]);
    const [dzielnicaAutocompleteArr, setDzielnicaAutocompleteArr] = useState<Dzielnica[] | []>([]);
    const [validationError, setValidationError] = useState<string | null>(null);
    const itemsArray = items?.split(',');

    const toggleForm = (): void => {
        setIsFormActive(prev => !prev);
    }

    const cityAutocomplete = (e: FormEvent<HTMLInputElement>): void => {
        const input = e.target as HTMLInputElement;
        if (input.value) {
            axiosClient({
                method: 'get',
                url: `/city-autocomplete?phrase=${input.value}`,
            })
                .then(res => setCityAutocompleteArr(res.data))
                .catch(err => console.error(err));
        } else {
            setCityAutocompleteArr([]);
        }
    }

    const cityFill = (city: string): void => {
        if (cityInputRef.current) {
            cityInputRef.current.value = city;
        }
        setCityAutocompleteArr([]);
    }

    const dzielnicaAutocomplete = (e: FormEvent<HTMLInputElement>): void => {
        const input = e.target as HTMLInputElement;
        const cityInput = cityInputRef.current;
        if (input.value) {
            axiosClient({
                method: 'get',
                url: `/dzielnica-autocomplete/${cityInput?.value ? cityInput.value : 'brak'}?phrase=${input.value}`
            })
                .then(res => setDzielnicaAutocompleteArr(res.data))
                .catch(err => console.error(err));
        } else {
            setDzielnicaAutocompleteArr([]);
        }
    }

    const dzielnicaFill = (dzielnica: string): void => {
        if (dzielnicaInputRef.current) {
            dzielnicaInputRef.current.value = dzielnica;
        }
        setDzielnicaAutocompleteArr([]);
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const nieruchomoscInput = form.querySelector('#nieruchomosc') as HTMLSelectElement;
        const rynekInput = form.querySelector('#rynek') as HTMLInputElement;
        const miejscowoscInput = form.querySelector('#miejscowosc') as HTMLInputElement;
        const dzielnicaInput = form.querySelector('#dzielnica') as HTMLInputElement;
        const cenaOdInput = form.querySelector('#od') as HTMLInputElement;
        const cenaDoInput = form.querySelector('#do') as HTMLInputElement;

        if (cenaOdInput?.value && (isNaN(parseFloat(cenaOdInput?.value)) || !isFinite(parseFloat(cenaOdInput?.value))) || cenaDoInput?.value && (isNaN(parseFloat(cenaDoInput?.value)) || !isFinite(parseFloat(cenaDoInput?.value)))) {
            setValidationError('Cena musi być liczbą');
            return;
        }

        if (+cenaOdInput?.value < 0 || +cenaDoInput?.value < 0) {
            setValidationError('Cena musi być dodatnia');
            return;
        }

        const formData = {
            nieruchomosc: nieruchomoscInput?.value,
            rynek: rynekInput?.value,
            miejscowosc: miejscowoscInput?.value,
            dzielnica: dzielnicaInput?.value,
            cenaOd: cenaOdInput?.value,
            cenaDo: cenaDoInput?.value
        };

        let url = '/wyszukiwarka?';

        Object.entries(formData).forEach(item => {
            url += `${item[0]}=${item[1]}&`
        });

        url = url.substring(0, url.length - 1);
        navigate(url);
    }

    return (
        <section className={styles.search}>
            <div className={styles.search__left}>
                <h2 className={styles.search__heading}>{heading}</h2>
                <div className={styles.search__list}>
                    {
                        itemsArray?.map(item => {
                            return (
                                <p key={item} className={styles.list__item}>
                                    <BsFillCheckCircleFill className={styles.item__icon} />
                                    <span className={styles.item__text}>{item}</span>
                                </p>
                            )
                        })
                    }

                </div>
            </div>
            <form onSubmit={handleSubmit} className={`${styles.search__form} ${isFormActive && styles.search__form_active}`}>
                <button onClick={toggleForm} type='button' className={styles.form__toggle}>{isFormActive ? 'Zwiń' : 'Rozwiń'}</button>
                <div className={styles.form__grid}>
                    <div className={styles.form__container}>
                        <label htmlFor="nieruchomosc" className={styles.form__label}>Nieruchomość</label>
                        <select id="nieruchomosc" className={styles.form__select}>
                            <option value="Dom">Dom</option>
                            <option value="Mieszkanie">Mieszkanie</option>
                            <option value="Działka">Działka</option>
                            <option value="Lokal">Lokal</option>
                        </select>
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="rynek" className={styles.form__label}>Rynek</label>
                        <select id="rynek" className={styles.form__select}>
                            <option value="Wtórny">Wtórny</option>
                            <option value="Pierwotny">Pierwotny</option>
                        </select>
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="miejscowosc" className={styles.form__label}>Miejscowość</label>
                        <input autoComplete='off' ref={cityInputRef} onChange={cityAutocomplete} id='miejscowosc' type="text" className={`${styles.form__input} ${cityAutocompleteArr.length > 0 && styles.form__input_expanded}`} />
                        {
                            cityAutocompleteArr.length > 0 &&
                            <div className={styles.form__autocomplete}>
                                {
                                    cityAutocompleteArr.map(item =>
                                        <button tabIndex={0} key={item.miasto} type='button' onClick={() => cityFill(item.miasto)} className={styles.form__autocomplete__button}>{item.miasto}</button>
                                    )
                                }
                            </div>
                        }
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="dzielnica" className={styles.form__label}>Dzielnica</label>
                        <input autoComplete='off' ref={dzielnicaInputRef} onChange={dzielnicaAutocomplete} id='dzielnica' type="text" className={`${styles.form__input} ${dzielnicaAutocompleteArr.length > 0 && styles.form__input_expanded}`} />
                        {
                            dzielnicaAutocompleteArr.length > 0 &&
                            <div className={styles.form__autocomplete}>
                                {
                                    dzielnicaAutocompleteArr.map(item =>
                                        <button tabIndex={0} key={item.dzielnica} type='button' onClick={() => dzielnicaFill(item.dzielnica)} className={styles.form__autocomplete__button}>{item.dzielnica}</button>
                                    )
                                }
                            </div>
                        }
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="od" className={styles.form__label}>Cena od</label>
                        <input min={0} id='od' type="number" className={styles.form__input} placeholder='od 100 000' />
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="do" className={styles.form__label}>Cena do</label>
                        <input min={0} id='do' type="number" className={styles.form__input} placeholder='do 10 000 000' />
                    </div>
                </div>
                <p role='alert' aria-live={validationError ? 'assertive' : 'off'} className={styles.form__error}>{validationError && validationError}</p>
                <button className={styles.form__button}>Wyszukaj</button>
            </form>
        </section>
    )
}

export default HomeSearch
