import { Link } from 'react-router-dom';

import styles from './hero.module.css';

interface Props {
    subHeading?: string;
    heading?: string;
    text?: string;
    linkText?: string;
    linkDestination?: string;
    image?: string;
}

const Hero = ({ subHeading, heading, text, linkText, linkDestination, image }: Props) => {
    return (
        <section className={styles.hero}>
            <div className={styles.hero__left}>
                <p className={styles.hero__title}>{subHeading}</p>
                <h2 className={styles.hero__heading}>{heading}</h2>
                <p className={styles.hero__text}>{text}</p>
                <a target='_blank' href={linkDestination ? linkDestination : '#'} className={styles.hero__button}>{linkText}</a>
            </div>
            <img src={`${process.env.REACT_APP_BACKEND_URL}/storage/assets/${image}`} alt="obraz" className={styles.hero__img} />
        </section>
    )
}

export default Hero
