import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Header, Footer } from '../sections';
import { Link } from 'react-router-dom';

import styles from './defaultLayout.module.css';

const DefaultLayout = () => {

    const [isPopupActive, setIsPopupActive] = useState<boolean>(true);

    function popupOff(): void {
        setIsPopupActive(false);
        localStorage.setItem('cookies_accept', 'true');
    }

    return (
        <>
            <Header />
            <Outlet />
            <Footer />
            {(isPopupActive && localStorage.getItem('cookies_accept') !== 'true') &&
                <div role='alert' aria-live='assertive' className={styles.cookiesPopup}>
                    <p className={styles.cookiesPopup__text}>
                        Ta strona wykorzystuje pliki cookies w celach statystycznych oraz w celu dostosowania naszych serwisów do indywidualnych potrzeb klientów. Zmiany ustawień dotyczących plików cookie można dokonać w dowolnej chwili modyfikując ustawienia przeglądarki. Korzystanie z tej strony bez zmian ustawień dotyczących cookies oznacza, że będą one zapisane w pamięci urządzenia.
                    </p>
                    <div className={styles.cookiesPopup__buttons}>
                        <button onClick={popupOff} className={styles.cookiesPopup__button}>Rozumiem</button>
                        <Link onClick={() => setIsPopupActive(false)} to='/polityka-prywatnosci' className={styles.cookiesPopup__button}>Polityka prywatności</Link>
                    </div>
                </div>
            }
        </>
    )
}

export default DefaultLayout
