import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosClient from '../../AxiosClient';
import { BsArrowRight, BsTelephone } from 'react-icons/bs';
import { AiOutlineLeft, AiOutlineRight, AiOutlinePrinter } from 'react-icons/ai';
import { HiOutlineMail } from 'react-icons/hi';
import { FaFacebook } from 'react-icons/fa';
import formatNumber from '../../utilities/formatNumber';
import Stars from '../../components/stars/Stars';
import { Helmet } from 'react-helmet';
import { FacebookShareButton } from 'react-share';

import styles from './oferta.module.css';


interface Offer {
    id: number;
    nazwa: string;
    miasto: string;
    dzielnica: string | null;
    numer_oferty: string;
    typ_nieruchomosci: string;
    rodzaj: string;
    liczba_pokoi: number | null;
    pietro: number | null;
    powierzchnia: number;
    cena_za_metr_kwadratowy: number;
    cena: number;
    rodzaj_budynku: string | null;
    standard: number | null;
    stan_lokalu: string | null;
    okna: string | null;
    balkon: number | null;
    opis: string;
    agent_id: number;
    client_id: null;
    aktywna: 1;
    created_at: string;
    updated_at: string;
}

interface Image {
    id: number;
    url: string;
    offer_id: number;
    is_main: number;
    created_at: string;
    updated_at: string;
}

interface Agent {
    id: number;
    imie: string;
    nazwisko: string;
    zdjecie: string;
    zawod: string;
    nr_telefonu: string;
    email: string;
    created_at: string;
    updated_at: string;
}

interface CustomProperty {
    id: number;
    offer_id: number;
    key: string;
    value: string;
    created_at: string;
    updated_at: string;
}

const Oferta = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [offer, setOffer] = useState<Offer | null>(null);
    const [images, setImages] = useState<Image[] | null>(null);
    const [isImageFullscreen, setIsImageFullscreen] = useState<boolean>(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [agent, setAgent] = useState<Agent | null>(null);
    const [customProperties, setCustomProperties] = useState<CustomProperty[] | [] | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { numer } = useParams();
    const description = useRef<HTMLPreElement>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: `/offer/${numer}`,
            cancelToken: source.token
        })
            .then(res => {
                const offerId = res.data.id;
                const agentId = res.data.agent_id;
                const offerRes = res.data;
                setOffer(offerRes);
                axiosClient({
                    method: 'get',
                    url: `/images/${offerId}`,
                    cancelToken: source.token
                })
                    .then(res => {
                        const array: Image[] = res.data;
                        const mainIndex = array.findIndex(obj => obj.is_main === 1);

                        if (mainIndex !== -1) {
                            const mainObj = array.splice(mainIndex, 1)[0];
                            array.unshift(mainObj);
                        }
                        setImages(array);
                        axiosClient({
                            method: 'get',
                            url: `/agent/${agentId}`,
                            cancelToken: source.token
                        })
                            .then(res => {
                                setAgent(res.data);
                                axiosClient({
                                    method: 'get',
                                    url: `/custom-properties/${offerId}`,
                                    cancelToken: source.token
                                })
                                    .then(res => {
                                        setCustomProperties(res.data);
                                        setIsLoading(false);
                                        setError(null);
                                    })
                                    .catch(err => {
                                        if (err?.response?.status === 404) {
                                            navigate('/not-found');
                                        }
                                        setIsLoading(false);
                                        setError('Coś poszło nie tak, spróbuj ponownie później...');
                                    })
                            })
                            .catch(err => {
                                if (err?.response?.status === 404) {
                                    navigate('/not-found');
                                }
                                setIsLoading(false);
                                setError('Coś poszło nie tak, spróbuj ponownie później...');

                            })

                    })
                    .catch(err => {
                        if (err?.response?.status === 404) {
                            navigate('/not-found');
                        }
                        setIsLoading(false);
                        setError('Coś poszło nie tak, spróbuj ponownie później...');

                    });
            })
            .catch(err => {
                if (err?.response?.status === 404) {
                    navigate('/not-found');
                }
                setIsLoading(false);
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            });

        return () => {
            source.cancel();
        }

    }, [numer]);

    const previousPhoto = (): void => {
        if (currentImageIndex !== 0) {
            setCurrentImageIndex(prev => prev - 1);
        }
        else {
            setCurrentImageIndex(images!.length - 1);
        }
    }

    const nextPhoto = (): void => {
        if (currentImageIndex !== images!.length - 1) {
            setCurrentImageIndex(prev => prev + 1);
        }
        else {
            setCurrentImageIndex(0);
        }
    }

    const printOffer = (): void => {
        window.print();
    }

    const zoomPhoto = (): void => {
        setIsImageFullscreen(true);
    }

    const unZoomPhoto = (): void => {
        setIsImageFullscreen(false);
    }

    if (error) {
        return <p role='alert' aria-live='assertive' className='error'>{error}</p>
    }

    return (
        <>
            <Helmet>
                <title>{offer?.nazwa}</title>
                <meta name='description' content={offer?.opis} />
            </Helmet>
            {isLoading ? <img className='loading' src="/loading.gif" alt="ładowanie" /> :
                <main className={styles.main}>
                    {
                        isImageFullscreen &&
                        <div className={styles.imgFullScreenContainer}>
                            <img className={styles.imgFullScreen} src={`${process.env.REACT_APP_BACKEND_URL}/storage/offers/${images && images[currentImageIndex].url}`} alt="powiększony obraz oferty" />
                            <div className={styles.imgFullScreenButtons}>
                                <button onClick={previousPhoto} className={styles.imgFullScreenButton}>
                                    <AiOutlineLeft />
                                </button>
                                <button onClick={nextPhoto} className={styles.imgFullScreenButton}>
                                    <AiOutlineRight />
                                </button>
                            </div>
                            <div onClick={unZoomPhoto} className={styles.imgFullScreenBg}></div>
                        </div>
                    }
                    <p className={styles.smallTop}>Strona główna <BsArrowRight aria-label='strzałka w prawo' /> Oferty</p>
                    <h1 className={styles.heading}>{offer?.nazwa}</h1>
                    <p className={styles.subheading}>{offer?.typ_nieruchomosci} na {offer?.rodzaj}</p>
                    <div className={styles.overview}>
                        <div className={styles.overview__left}>
                            <p className={styles.overview__text}>Symbol oferty: <b>{offer?.numer_oferty}</b></p>
                            <p className={styles.overview__text}>Powierzchnia: <b>{offer?.powierzchnia}m&sup2;</b></p>
                            <p className={styles.overview__text}>Cena za m&sup2;: <b>{offer && formatNumber(offer.cena_za_metr_kwadratowy)} PLN</b></p>
                            {
                                offer?.liczba_pokoi &&
                                <p className={styles.overview__text}>Liczba pokoi: <b>{offer.liczba_pokoi}</b></p>
                            }
                            {
                                offer?.pietro !== null &&
                                <p className={styles.overview__text}>Piętro: <b>{offer?.pietro === 0 ? 'parter' : offer?.pietro}</b></p>
                            }
                            {
                                offer?.rodzaj_budynku &&
                                <p className={styles.overview__text}>Rodzaj budynku: <b>{offer.rodzaj_budynku}</b></p>
                            }
                            {
                                offer?.standard &&
                                <p className={styles.overview__text}>Standard: <Stars ileGwiazdek={offer.standard} /></p>
                            }
                            <p className={styles.overview__price}>Cena: {offer && formatNumber(offer.cena)} PLN</p>
                        </div>
                        <div className={styles.overview__right}>
                            <img onClick={zoomPhoto} className={styles.overview__img} src={`${process.env.REACT_APP_BACKEND_URL}/storage/offers/${images && images[currentImageIndex].url}
`} alt="obraz oferty" />
                            <div className={styles.overview__buttons}>
                                <div className={`${styles.overview__button} ${styles.overview__button_facebook}`}>
                                    <FacebookShareButton aria-label='Udostępnij na Facebooku' className={styles.overview__button} url={`${process.env.REACT_APP_BACKEND_URL}/metatags/${numer}`}>
                                        <FaFacebook />
                                    </FacebookShareButton>
                                </div>
                                <button onClick={printOffer} aria-label='Wydrukuj' className={styles.overview__button}>
                                    <AiOutlinePrinter />
                                </button>
                                <div className={styles.overview__arrows}>
                                    <button onClick={previousPhoto} aria-label='Poprzednie zdjęcie' className={styles.overview__button}>
                                        <AiOutlineLeft className={styles.overview__arrow} />
                                    </button>
                                    <button onClick={nextPhoto} aria-label='Następne zdjęcie' className={styles.overview__button}>
                                        <AiOutlineRight className={styles.overview__arrow} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.more}>
                        <div className={styles.more__left}>
                            <div className={styles.agent}>
                                <img className={styles.agent__img} src={`${process.env.REACT_APP_BACKEND_URL}/storage/agents/${agent?.zdjecie}`} alt={`${agent!.imie} ${agent!.nazwisko}`} />
                                <div className={styles.agent__right}>
                                    <p className={styles.agent__name}>{`${agent?.imie} ${agent?.nazwisko}`}</p>
                                    <p className={styles.agent__job}>{agent?.zawod}</p>
                                    <p className={styles.agent__email}>
                                        <HiOutlineMail className={styles.agent__icon} />
                                        <a href={`mailto:${agent?.email}`} className={styles.agent__email__text}>{agent?.email}</a>
                                    </p>
                                    <p className={styles.agent__phone}>
                                        <BsTelephone className={styles.agent__icon} />
                                        <a className={styles.agent__phone__text} href={`tel:${agent?.nr_telefonu}`}>{agent?.nr_telefonu}</a>
                                    </p>
                                </div>
                            </div>
                            <h2 className={styles.more__heading}>Opis nieruchomości</h2>
                            <pre ref={description} className={styles.more__description}>{offer?.opis}</pre>
                            <p className={`${styles.more__heading} ${styles.more__notaPrawna}`}>Nota prawna</p>
                            <p className={`${styles.more__description} ${styles.more__notaPrawna}`}>Opis oferty zawarty na stronie internetowej sporządzany jest na podstawie oględzin nieruchomości oraz informacji uzyskanych od właściciela, może podlegać aktualizacji i nie stanowi oferty określonej w art. 66 i następnych K.C.</p>
                        </div>
                        <div className={styles.more__right}>
                            {
                                offer?.rodzaj_budynku &&
                                <p className={styles.more__right__text}>Rodzaj budynku: <b>{offer.rodzaj_budynku}</b></p>
                            }
                            {
                                offer?.stan_lokalu &&
                                <p className={styles.more__right__text}>Stan lokalu: <b>{offer.stan_lokalu}</b></p>
                            }
                            {
                                offer?.okna &&
                                <p className={styles.more__right__text}>Okna: <b>{offer.okna}</b></p>
                            }
                            {
                                offer?.balkon !== null &&
                                <p className={styles.more__right__text}>Balkon: <b>{offer!.balkon ? 'jest' : 'brak'}</b></p>
                            }
                            {
                                (customProperties && customProperties?.length > 0) &&
                                customProperties.map(property => {
                                    return <p key={property.id} className={styles.more__right__text}>{property.key}: <b>{property.value}</b></p>
                                })
                            }
                        </div>
                    </div>
                </main>
            }
        </>
    )
}

export default Oferta
