import { ReactNode } from 'react';
import OfferTile from '../../components/offerTile/OfferTile';

import styles from './offerGroup.module.css';

interface Props {
    heading: string;
    children: ReactNode;
}

const OfferGroup = ({ heading, children }: Props) => {
    return (
        <section className={styles.section}>
            <h2 className={styles.section__heading}>{heading}</h2>
            <div className={styles.section__grid}>
                {children}
            </div>
        </section>
    )
}

export default OfferGroup
