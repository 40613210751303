import { useState, useEffect } from 'react';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { PiInstagramLogoFill } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import axiosClient from '../../AxiosClient';
import axios from 'axios';

import styles from './footer.module.css';

interface Response {
    id: number;
    location: string;
    key: string;
    value: string;
    created_at: string;
    updated_at: string;
}

const Footer = () => {
    const [text, setText] = useState<Response[] | null>(null);
    const [contact, setContact] = useState<Response[] | null>(null);
    const [socialMedia, setSocialMedia] = useState<Response[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/content/footer',
            cancelToken: source.token
        })
            .then(res => {
                setText(res.data);
                axiosClient({
                    method: 'get',
                    url: '/content/contact',
                    cancelToken: source.token
                })
                    .then(res => {
                        setContact(res.data);
                        axiosClient({
                            method: 'get',
                            url: '/content/header',
                            cancelToken: source.token
                        })
                            .then(res => {
                                setSocialMedia(res.data);
                                setError(null);
                            })
                            .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));
                    })
                    .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));
            })
            .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'))

        return () => {
            source.cancel();
        }

    }, []);

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.footer__top}>
                <div className={styles.footer__first}>
                    <img className={styles.footer__logo} src={`${process.env.REACT_APP_BACKEND_URL}/storage/assets/logo.svg`} alt="logo firmy" />
                    <p className={styles.footer__first__text}>{text?.filter((item) => {
                        return item.key === 'text';
                    })[0].value}</p>
                    <div className={styles.footer__first__socialMedia}>
                        <a aria-label='Facebook' href={socialMedia?.filter((item) => {
                            return item.key === 'facebook';
                        })[0].value} target='_blank' className={styles.footer__socialLink}>
                            <FaFacebookF className={styles.footer__socialLink__icon} />
                        </a>
                        <a aria-label='Twitter' href={socialMedia?.filter((item) => {
                            return item.key === 'twitter';
                        })[0].value} target='_blank' className={styles.footer__socialLink}>
                            <FaTwitter className={styles.footer__socialLink__icon} />
                        </a>
                        <a aria-label='Instagram' href={socialMedia?.filter((item) => {
                            return item.key === 'instagram';
                        })[0].value} target='_blank' className={styles.footer__socialLink}>
                            <PiInstagramLogoFill className={styles.footer__socialLink__icon} />
                        </a>
                        <a aria-label='Linkedin' href={socialMedia?.filter((item) => {
                            return item.key === 'linkedin';
                        })[0].value} target='_blank' className={styles.footer__socialLink}>
                            <FaLinkedinIn className={styles.footer__socialLink__icon} />
                        </a>
                        <a aria-label='YouTube' href={socialMedia?.filter((item) => {
                            return item.key === 'youtube';
                        })[0].value} target='_blank' className={styles.footer__socialLink}>
                            <FaYoutube className={styles.footer__socialLink__icon} />
                        </a>
                    </div>
                </div>
                <nav className={styles.footer__column}>
                    <p className={styles.footer__column__heading}>Firma</p>
                    <div className={styles.footer__column__links}>
                        <Link to='/o-nas' className={styles.footer__navLink}>Działalność</Link>
                        <Link to='/kontakt' className={styles.footer__navLink}>Kontakt</Link>
                    </div>
                </nav>
                <nav className={styles.footer__column}>
                    <p className={styles.footer__column__heading}>Oferty</p>
                    <div className={styles.footer__column__links}>
                        <Link to='/wyszukiwarka?rodzaj=wynajem' className={styles.footer__navLink}>Wynajem</Link>
                        <Link to='/wyszukiwarka?rodzaj=sprzedaż' className={styles.footer__navLink}>Sprzedaż</Link>
                        <Link to='/wyszukiwarka' className={styles.footer__navLink}>Nieruchomości</Link>
                        <Link to='/wyszukiwarka?nieruchomosc=Działka' className={styles.footer__navLink}>Działki</Link>
                    </div>
                </nav>
                <div className={styles.footer__column}>
                    <p className={styles.footer__column__heading}>Kontakt</p>
                    <div className={styles.footer__column__links}>
                        <p className={styles.footer__navLink}>{contact?.filter((item) => {
                            return item.key === 'siedzibaMiasto';
                        })[0].value}</p>
                        <p className={styles.footer__navLink}>{contact?.filter((item) => {
                            return item.key === 'siedzibaAdres';
                        })[0].value}</p>
                        <a className={styles.footer__navLink} href={`tel:${contact?.filter((item) => {
                            return item.key === 'mobile';
                        })[0].value}`}>{contact?.filter((item) => {
                            return item.key === 'mobile';
                        })[0].value}</a>
                        <a className={styles.footer__navLink} href={`mailto:${contact?.filter((item) => {
                            return item.key === 'email';
                        })[0].value}`}>{contact?.filter((item) => {
                            return item.key === 'email';
                        })[0].value}</a>
                    </div>
                </div>
            </div>
            <p className={styles.footer__bottom}>Copyright &copy; 2023 ADM Home & Event | All Rights Reserved | <a className={styles.footer__bottom__link} href="/polityka-prywatnosci">Warunki korzystania z serwisu</a> | <Link className={styles.footer__bottom__link} to="/polityka-prywatnosci">Polityka prywatności</Link></p>
        </footer>
    )
}

export default Footer
