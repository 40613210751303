import { Link } from 'react-router-dom';

import styles from './invitation.module.css';

interface Props {
    heading?: string;
    text?: string;
    img?: string;
}

const Invitation = ({ heading, text, img }: Props) => {
    return (
        <section className={styles.invitation}>
            <img src={`${process.env.REACT_APP_BACKEND_URL}/storage/assets/${img}`} alt="Edyta Gąstalik" className={styles.invitation__img} />
            <div className={styles.invitation__right}>
                <h2 className={styles.invitation__heading}>{heading}</h2>
                <p className={styles.invitation__text}>{text}</p>
                <Link to='/o-nas' className={styles.invitation__button}>Więcej o naszej firmie</Link>
            </div>
        </section>
    )
}

export default Invitation
