import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import styles from './notFound.module.css';

const NotFound = () => {
    useEffect(() => {
        document.body.style.backgroundColor = '#F8F8F8';
    }, []);

    return (
        <div className={styles.main}>
            <h1 className={styles.heading}>Ups, nie znaleziono strony o podanym adresie...</h1>
            <p className={styles.status}>404</p>
            <Link className={styles.link} to='/'>Strona główna</Link>
        </div>
    )
}

export default NotFound
