const formatNumber = (number: number): string => {
    const numberString = number.toString();

    if (numberString.includes('.')) {
        const [integerPart, decimalPart] = numberString.split('.');

        if (integerPart.length > 3) {
            const integerChunks = [];

            for (let i = integerPart.length - 1; i >= 0; i -= 3) {
                const chunk = integerPart.substring(Math.max(0, i - 2), i + 1);
                integerChunks.unshift(chunk);
            }

            const formattedInteger = integerChunks.join(' ');

            return `${formattedInteger}.${decimalPart}`;
        }
    } else if (numberString.length > 3) {
        const chunks = [];

        for (let i = numberString.length - 1; i >= 0; i -= 3) {
            const chunk = numberString.substring(Math.max(0, i - 2), i + 1);
            chunks.unshift(chunk);
        }

        return chunks.join(' ');
    }

    return numberString;
}

export default formatNumber;