import { useState, useEffect, useRef, FormEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { BsArrowRight, BsFillSkipStartFill, BsFillSkipEndFill } from 'react-icons/bs';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { OfferGroup } from '../../sections';
import OfferTile from '../../components/offerTile/OfferTile';
import axiosClient from '../../AxiosClient';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import styles from './wyszukiwarka.module.css';

interface Offer {
    id: number;
    nazwa: string;
    miasto: string;
    dzielnica: string | null;
    numer_oferty: string;
    typ_nieruchomosci: string;
    rodzaj: string;
    liczba_pokoi: number | null;
    pietro: number | null;
    powierzchnia: number;
    cena_za_metr_kwadratowy: number;
    cena: number;
    rodzaj_budynku: string | null;
    standard: number | null;
    stan_lokalu: string | null;
    okna: string | null;
    balkon: number | null;
    opis: string;
    agent_id: number;
    client_id: null;
    aktywna: 1;
    created_at: string;
    updated_at: string;
}

interface Link {
    url: string | null;
    label: string;
    active: boolean;
}

interface Response {
    current_page: number;
    data: Offer[] | [];
    first_page_url: string;
    from: number | null;
    last_page: number;
    last_page_url: string;
    links: Link[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number | null;
    total: number;
}

interface Miasto {
    miasto: string;
}

const Wyszukiwarka = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [offers, setOffers] = useState<Response | null>(null);
    const [offersBackup, setOffersBackup] = useState<Offer[] | null>(null);
    const [page, setPage] = useState<number>(1);
    const [cityAutocompleteArr, setCityAutocompleteArr] = useState<Miasto[] | []>([]);
    const [url, setUrl] = useState<string | undefined>(undefined);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const cityInputRef = useRef<HTMLInputElement>(null);
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();
        const nieruchomosc = searchParams.get('nieruchomosc') || '';
        const rodzaj = searchParams.get('rodzaj') || '';
        const rynek = searchParams.get('rynek') || '';
        const miejscowosc = searchParams.get('miejscowosc') || '';
        const dzielnica = searchParams.get('dzielnica') || '';
        const cenaOd = searchParams.get('cenaOd') || '';
        const cenaDo = searchParams.get('cenaDo') || '';
        const urlTemp = `/offers?nieruchomosc=${nieruchomosc}&rodzaj=${rodzaj}&rynek=${rynek}&miejscowosc=${miejscowosc}&dzielnica=${dzielnica}&cenaOd=${cenaOd}&cenaDo=${cenaDo}`
        setUrl(urlTemp);

        axiosClient({
            method: 'get',
            url: urlTemp,
            cancelToken: source.token
        })
            .then(res => {
                setOffers(res.data);
                axiosClient({
                    method: 'get',
                    url: '/latest-offers',
                    cancelToken: source.token
                })
                    .then(res => {
                        setOffersBackup(res.data);
                        setError(null);
                        setIsLoading(false);
                        isMounted.current = true;
                    })
                    .catch(err => {
                        setIsLoading(false);
                        setError('Coś poszło nie tak, spróbuj ponownie później...');
                    })
            })
            .catch(err => {
                setIsLoading(false);
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            });

        return () => {
            source.cancel();
        }

    }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (isMounted.current) {
            axiosClient({
                method: 'get',
                url: url + `&page=${page}`,
                cancelToken: source.token
            })
                .then(res => {
                    setOffers(res.data);
                    setError(null);
                    setIsLoading(false);
                })
                .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));

        }

        return () => {
            source.cancel();
        }

    }, [page]);




    const cityAutocomplete = (e: FormEvent<HTMLInputElement>): void => {
        const input = e.target as HTMLInputElement;
        if (input.value) {
            axiosClient({
                method: 'get',
                url: `/city-autocomplete?phrase=${input.value}`,
            })
                .then(res => setCityAutocompleteArr(res.data))
                .catch(err => console.error(err));
        } else {
            setCityAutocompleteArr([]);
        }
    }

    const cityFill = (city: string): void => {
        if (cityInputRef.current) {
            cityInputRef.current.value = city;
            search(null, cityInputRef.current);
        }
        setCityAutocompleteArr([]);
    }

    const firstPage = (): void => {
        setPage(1);
    }

    const prevPage = (): void => {
        setPage(prev => prev - 1);
    }

    const nextPage = (): void => {
        setPage(prev => prev + 1);
    }

    const lastPage = (): void => {
        setPage(offers!.last_page);
    }

    const search = (e: React.ChangeEvent | null, inputElement?: HTMLInputElement): void => {
        let input;
        if (e) {
            input = e.target as HTMLElement;
        } else if (inputElement) {
            input = inputElement;
        }

        if (input) {
            const form = input.closest('form') as HTMLFormElement;
            const nieruchomosc = form.querySelector('#nieruchomosc') as HTMLSelectElement;
            const rodzaj = form.querySelector('#rodzaj') as HTMLSelectElement;
            const rynek = form.querySelector('#rynek') as HTMLInputElement;
            const miejscowosc = form.querySelector('#miejscowosc') as HTMLInputElement;
            const cenaOd = form.querySelector('#cenaOd') as HTMLInputElement;
            const cenaDo = form.querySelector('#cenaDo') as HTMLInputElement;
            const powierzchnia = form.querySelector('#powierzchnia') as HTMLInputElement;
            const liczbaPokoi = form.querySelector('#liczbaPokoi') as HTMLInputElement;
            const pietro = form.querySelector('#pietro') as HTMLInputElement;

            const urlTemp = `/offers?nieruchomosc=${nieruchomosc?.value || ''}&rodzaj=${rodzaj?.value || ''}&rynek=${rynek?.value || ''}&miejscowosc=${miejscowosc?.value || ''}&cenaOd=${cenaOd?.value || ''}&cenaDo=${cenaDo?.value || ''}&powierzchnia=${powierzchnia?.value}&liczbaPokoi=${liczbaPokoi?.value || ''}&pietro=${pietro?.value || ''}`;
            setUrl(urlTemp);

            axiosClient({
                method: 'get',
                url: urlTemp
            })
                .then(res => {
                    setError(null);
                    setOffers(res.data);
                    setPage(1);
                })
                .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));
        }
    }

    if (error) {
        return <p role='alert' aria-live='assertive' className='error'>{error}</p>
    }

    return (
        <>
            <Helmet>
                <title>Wyszukiwarka ofert</title>
                <meta name='description' content='Przeszukaj naszą bazę ogłoszeń mieszkań na sprzedaż, wynajem, do inwestycji.' />
            </Helmet>
            {
                isLoading ? <img src="/loading.gif" alt="ładowanie" className='loading' /> :
                    <main className={styles.main}>
                        <div className={styles.main__top}>
                            <p className={styles.smallTop}>Strona Główna <BsArrowRight /> Oferty</p>
                            <h1 className={styles.heading}>Znajdź oferty</h1>
                            <p className={styles.subheading}>Przeszukaj naszą bazę ogłoszeń mieszkań na sprzedaż, wynajem, do inwestycji.</p>
                        </div>
                        <form className={styles.form}>
                            <div className={styles.form__top}>
                                <div className={styles.form__container}>
                                    <label htmlFor="nieruchomosc" className={styles.form__label}>Nieruchomość</label>
                                    <select onChange={(e) => search(e)} defaultValue='' className={styles.form__select} id="nieruchomosc">
                                        <option className={styles.form__option} value="">Wybierz</option>
                                        <option className={styles.form__option} value="Dom">Dom</option>
                                        <option className={styles.form__option} value="Mieszkanie">Mieszkanie</option>
                                        <option className={styles.form__option} value="Lokal">Lokal</option>
                                        <option className={styles.form__option} value="Działka">Działka</option>
                                    </select>
                                </div>
                                <div className={styles.form__container}>
                                    <label htmlFor="rodzaj" className={styles.form__label}>Rodzaj</label>
                                    <select onChange={(e) => search(e)} defaultValue='' className={styles.form__select} id="rodzaj">
                                        <option className={styles.form__option} value="">Wybierz</option>
                                        <option className={styles.form__option} value="Sprzedaż">Sprzedaż</option>
                                        <option className={styles.form__option} value="Wynajem">Wynajem</option>
                                    </select>
                                </div>
                                <div className={styles.form__container}>
                                    <label htmlFor="rynek" className={styles.form__label}>Rynek:</label>
                                    <select onChange={(e) => search(e)} defaultValue='' className={styles.form__select} id="rynek">
                                        <option className={styles.form__option} value="">Wybierz</option>
                                        <option className={styles.form__option} value="Pierwotny">Pierwotny</option>
                                        <option className={styles.form__option} value="Wtórny">Wtórny</option>
                                    </select>
                                </div>
                                <div className={styles.form__container}>
                                    <label htmlFor="miejscowosc" className={styles.form__label}>Miejscowość</label>
                                    <input ref={cityInputRef} onChange={(e) => { cityAutocomplete(e); search(e); }} autoComplete='off' id='miejscowosc' type="text" className={`${styles.form__input} ${cityAutocompleteArr.length > 0 && styles.form__input_expanded}`} />
                                    {
                                        cityAutocompleteArr.length > 0 &&
                                        <div className={styles.form__autocomplete}>
                                            {
                                                cityAutocompleteArr.map(item =>
                                                    <button tabIndex={0} key={item.miasto} type='button' onClick={() => cityFill(item.miasto)} className={styles.form__autocomplete__button}>{item.miasto}</button>
                                                )
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={styles.form__bottom}>
                                <p className={styles.form__bottom__label}>Dodatkowe parametry wyszukiwania</p>
                                <div className={styles.form__bottom__group}>
                                    <input onChange={(e) => search(e)} id='cenaOd' placeholder='Cena od' aria-label='Cena od' type="number" min={0} className={styles.form__input} />
                                    <input onChange={(e) => search(e)} id='cenaDo' placeholder='Cena do' aria-label='Cena do' type="number" min={0} className={styles.form__input} />
                                    <input onChange={(e) => search(e)} step={0.01} id='powierzchnia' placeholder='Powierzchnia' aria-label='Powierzchnia' min={0} type="number" className={styles.form__input} />
                                    <input onChange={(e) => search(e)} id='liczbaPokoi' placeholder='Liczba pokoi' aria-label='Liczba pokoi' min={0} type="number" className={styles.form__input} />
                                    <input onChange={(e) => search(e)} id='pietro' placeholder='Piętro' aria-label='Piętro' type="number" min={0} className={styles.form__input} />
                                </div>
                            </div>
                            {/* <button className={styles.form__button}>Wyszukaj</button> */}
                        </form>
                        {
                            (offers?.data && offers.data.length > 0) ?
                                <OfferGroup heading='Oferty'>
                                    {
                                        offers.data.map(item =>
                                            <OfferTile
                                                key={item.id}
                                                id={item.numer_oferty}
                                                city={item.miasto}
                                                type={`${item.typ_nieruchomosci} na ${item.rodzaj}`}
                                                surface={item.powierzchnia}
                                                rooms={item?.liczba_pokoi}
                                                floor={item?.pietro}
                                                pricePerSquareMeter={item.cena_za_metr_kwadratowy}
                                                price={item.cena}
                                            />)
                                    }
                                </OfferGroup>
                                :
                                <p className={styles.noResults}>Jeszcze nie mamy takiej oferty, ale chętnie poszukamy jej dla Ciebie. Zadzwoń do nas: 608 663 172</p>
                        }
                        {
                            (offers?.data && offers.data.length > 0) &&
                            <>
                                <div className={styles.pagination}>
                                    <button aria-disabled={page === 1 ? true : false} disabled={page === 1 ? true : false} onClick={firstPage} className={`${styles.pagination__button} ${page === 1 && styles.pagination__button_disabled}`}>
                                        <BsFillSkipStartFill className={styles.pagination__icon} />
                                    </button>

                                    <button aria-disabled={page === 1 ? true : false} disabled={page === 1 ? true : false} className={`${styles.pagination__button} ${page === 1 && styles.pagination__button_disabled}`} onClick={prevPage}>
                                        <MdNavigateBefore className={styles.pagination__icon} />
                                    </button>

                                    <button aria-disabled={page === offers.last_page ? true : false} disabled={page === offers.last_page ? true : false} onClick={nextPage} className={`${styles.pagination__button} ${page === offers.last_page && styles.pagination__button_disabled}`}>
                                        <MdNavigateNext className={styles.pagination__icon} />
                                    </button>

                                    <button aria-disabled={page === offers.last_page ? true : false} disabled={page === offers.last_page ? true : false} onClick={lastPage} className={`${styles.pagination__button} ${page === offers.last_page && styles.pagination__button_disabled}`}>
                                        <BsFillSkipEndFill className={styles.pagination__icon} />
                                    </button>
                                </div>
                                <p className={styles.page}>Strona: {page}</p>
                            </>
                        }
                        <OfferGroup heading='Najnowsze oferty'>
                            {
                                offersBackup?.map(offer => {
                                    return (
                                        <OfferTile
                                            key={offer.id}
                                            id={offer.numer_oferty}
                                            city={offer.miasto}
                                            type={`${offer.typ_nieruchomosci} na ${offer.rodzaj}`}
                                            surface={offer.powierzchnia}
                                            rooms={offer?.liczba_pokoi}
                                            floor={offer?.pietro}
                                            pricePerSquareMeter={offer.cena_za_metr_kwadratowy}
                                            price={offer.cena}
                                        />
                                    )
                                })
                            }
                        </OfferGroup>
                    </main>
            }
        </>
    )
}

export default Wyszukiwarka
